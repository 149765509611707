export default function Footer() {
    return (
        <>
            <div className="footer-name source-code-pro">
                <p>Nathan Hannan-McCulloch</p>
            </div>
            <div className="footer-year source-code-pro">
                <p>2024</p>
            </div>
        </>
    )
}